.likertwrapper {
    flex-direction: row;
}

.likertbox {
    background-color: #9babc1;
    border: 1px solid #5388c3;
    border-radius: 2px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    cursor: pointer;
}

.likertbox:hover {
    background-color: #888888;
    box-shadow: 4px 4px 4px #999999;
}

.likertbox:hover .checked, .likertbox.checked {
    background-color: #2954f3;
}


.mintext, .maxtext {
    margin: 2px 20px;
    font-size: 90%;
}

.mintext {
    color: darkred;
    text-align: right;
}

.maxtext {
    color: darkred;
    text-align: left;

}

.titel {
    font-size: 20px;
    background-color: #bbc2d9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin-bottom: 30px;
}

.likert {
    background-color: #ddd;
    border: 1px solid #aaf;
    border-radius: 5px;
    font-size: 125%;
    padding: 10px;
    display: flex;
}

.itemtext {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
}

.eblFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #dddddd;
}

.header {
    width: 100%;
    font-size: 30px;
}

.lowOnTime, .singleExplanation.highlight.lowOnTime {
    background-color: #de8c8c;
}

.singleExample.highlight, .singleExplanation.highlight {
    background-color: #e3e3f3;
}

.exampleContainer {
    display: flex;
    flex-direction: column;
    flex: 0 1 800px;
    min-width: 500px;
    margin: 5px;
}

.exampleHeader {
    align-self: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #bbbbbb;
    border-radius: 2px;
}

.examples {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.singleExample {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 40%;
    margin: 10px;
    padding: 10px;
    background-color: #bbbbbb;
    border: 1px solid #1a1ab080;
    border-radius: 2px;
}

.mousePointer {
    cursor: pointer;
}

.question {
    font-style: italic;
    margin-top: 3px;
    color: #000099;
}

.solution {
    display: flex;
    margin-top: 5px;
    font-size: 120%;
    color: darkred;
}

.exampleCount {
    font-weight: bold;
}

.explanations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    flex: 0 1 350px;
    min-width: 250px;
    margin: 5px;
    background-color: #bbbbbb;
    border-radius: 2px;
}

.singleExplanation {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border: 1px solid #1a1ab080;
    border-radius: 2px;
}

.explanationHeader {
    font-style: italic;
    color: #000099;
    border-radius: 2px;
}

.editable {
    margin: 10px;
}

.buttonDiv {
    width: 100%;
    height: 80%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

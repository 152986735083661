.container {
    display: flex;
    max-width: 800px;
    min-width: 500px;
    padding: 50px;
    font-size: 15px;
    line-height: 150%;
}

.column {
    flex-direction: column;
}

.header {

}

.stimulus {
    background-color: #e6eff8;
    padding: 5px;
    margin-bottom: 20px;
}

.question {
    font-style: italic;
    margin-top: 6px;
    color: #000099;
}
.correct {
    font-size: 40px;
    color: green;
}

.wrong {
    font-size: 40px;
    color: darkred;
}

.input {

}

.textArea {
    margin-bottom: 10px;
}

.textAreaWrapper {
    display: flex;
    flex-direction: column;
    min-width: 100px;
}

.radioWrapper {
    display: flex;
    flex-direction: column;
}

.radioGroup {
    padding-left: 15%
}

.radioGroup label:hover {
    background-color: #dddddd;
}

.likert {

}
.zone {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ddd;
    border: 1px solid #aaf;
    border-radius: 5px;
    width: 800px;
}

.continue {
    color: darkblue;
    bottom: 10%;
    font-size: 18px;
    justify-content: space-around;
}

.content {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 10%;
    bottom: 25%;
    font-size: 16px;
    line-height: 150%;
}

.content strong {
    color: darkblue;
}

/*.content p {*/
/*    width: 100%;*/
/*    line-height: 130%;*/
/*    font-size: 18px;*/
/*}*/

.content h1 {
    font-size: 20px;
    text-align: center;
}

.content h2 {
    font-size: 18px;
}

.content div {
    overflow-y: auto;
    -webkit-overflow-y: auto;
    -webkit-overflow-scrolling: touch;

}

.content svg {
    color: darkblue;
}

.whiteline {
    border-top: 1px solid white;
}

.radio label:nth-child(1) {
    color: darkblue;
}

.radio label:nth-child(n+3) {
    margin-top: 10px;
}

.radio label:nth-child(n+2):hover {
    background-color: #ccc;
}

